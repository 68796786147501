import { memo, useEffect, useState } from 'react';
import { MainContainer, СontentWidthLimiter } from './styled';
import { Header } from './Header';
import { Main } from './Main';
import { Sidebar } from './Sidebar';
import { IBreadcrumb } from '@abit/ui/types/window';
import { Notifications } from './Notifications';
import { SearchBar } from './SearchBar';

export const Content = memo(() => {
  const [pageTitle, setPageTitle] = useState('');
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([]);

  useEffect(() => {
    if (!window.abit) return;

    window.abit.addBreadcrumb = (breadcrumb) => {
      setBreadcrumbs((state) => {
        if (state.find(({ id }) => id === breadcrumb.id)) return state;

        const newState = [...state, breadcrumb];
        return newState;
      });
    };

    window.abit.replaceBreadcrumb = (breadcrumb) => {
      setBreadcrumbs((state) => {
        const index = state.findIndex(({ id }) => id === breadcrumb.id);

        if (index === -1) return state;

        state.push(breadcrumb);

        return state;
      });
    };

    window.abit.deleteBreadcrumb = (breadcrumbId) => {
      if (!breadcrumbId) return;

      setBreadcrumbs((state) => state.filter(({ id }) => id !== breadcrumbId));
    };

    return () => {
      if (!window.abit) return;

      window.abit.addBreadcrumb = () => {};
      window.abit.replaceBreadcrumb = () => {};
      window.abit.deleteBreadcrumb = () => {};
    };
  }, []);

  return (
    <>
      <Header title={pageTitle} breadcrumbs={breadcrumbs} />
      <Notifications />
      <SearchBar />
      <MainContainer as={СontentWidthLimiter}>
        <Sidebar />
        <Main setBreadcrumbs={setBreadcrumbs} setPageTitle={setPageTitle} />
      </MainContainer>
    </>
  );
});
