import { Chart } from 'react-google-charts';
import { SpaceBox } from '@abit/ui/components/SpaceBox';

import { ILog } from 'api/logs';
import { memo, useMemo, useState } from 'react';
import { Label } from '@abit/ui/components/Label';
import { Text } from '@abit/ui/components/Text';
import { Input } from '@abit/ui/components/Input';

interface IProps {
  data: ILog[];
}

export const Charts: React.FC<IProps> = memo(({ data }) => {
  const [end, setEnd] = useState(new Date());
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 1);
  const [start, setStart] = useState(startDate);
  const [realm, setRealm] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [realmOptions, valueOptions] = useMemo(() => {
    const bufer = {
      realm: new Set<string>(),
      value: new Set<string>(),
    };

    data.forEach((item) => {
      bufer.realm.add(item.realm);
      if (realm && item.realm === realm) bufer.value.add(item.value);
    });

    const realmArray = Array.from(bufer.realm);
    const valueArray = Array.from(bufer.value);

    realmArray.push('');
    valueArray.push('');

    realmArray.reverse();
    valueArray.reverse();

    return [realmArray, valueArray];
  }, [data, realm]);

  const filteredData = data.filter((item) => {
    const date = new Date(item.createData);
    if (!(date >= start && date < end)) return false;

    let result = true;

    if (realm) result = item.realm === realm;
    if (result && value) result = item.value === value;

    return result;
  });

  const dataY: [string, number][] = [];
  // const dataY: number[] = [];

  let bufer = [...filteredData];

  if (filteredData.length) {
    for (
      let i = new Date(filteredData[filteredData.length - 1].createData);
      i < new Date(filteredData[0].createData);
      i.setMinutes(i.getMinutes() + 15)
    ) {
      let count = 0;
      bufer = bufer.filter((item) => {
        const date = new Date(item.createData);
        const interval = new Date(i.getTime());
        interval.setMinutes(i.getMinutes() + 15);

        if (i <= date && interval > date) {
          count++;
          return false;
        }

        return true;
      });

      dataY.push([i.toLocaleString(), count]);
    }
  }

  return (
    <SpaceBox py={8}>
      <SpaceBox mb={6} style={{ display: 'flex' }}>
        <Label name="Start">
          <Input
            type="datetime-local"
            name="start"
            value={start.toJSON().slice(0, 16)}
            onChange={(event) => setStart(new Date(event.currentTarget.value))}
          />
        </Label>
        <Label name="End" mx={4}>
          <Input
            type="datetime-local"
            name="end"
            value={end.toJSON().slice(0, 16)}
            max={new Date().toJSON().slice(0, 16)}
            onChange={(event) => setEnd(new Date(event.currentTarget.value))}
          />
        </Label>
        <Label name="Realm">
          <select
            value={realm}
            onChange={(event) => {
              setRealm(event.currentTarget.value);
              setValue('');
            }}
          >
            {realmOptions.map((option) => (
              <option value={option} key={option}>
                {option || '[disabled]'}
              </option>
            ))}
          </select>
          <select value={value} onChange={(event) => setValue(event.currentTarget.value)}>
            {valueOptions.map((option) => (
              <option value={option} key={option}>
                {option || '[disabled]'}
              </option>
            ))}
          </select>
        </Label>
      </SpaceBox>
      {dataY.length ? (
        <Chart chartType="Bar" data={[['Days', 'Logs Count'], ...dataY]} width="100%" height="400px" />
      ) : (
        <Text variant="body1Regular">No data</Text>
      )}
    </SpaceBox>
  );
});
