/* eslint-disable @typescript-eslint/no-explicit-any */
import './global-exports';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { store } from 'store';

import { createRoot } from 'react-dom/client';
import { App } from 'components/App';
import { WindowABIT } from '@abit/ui/types/window';

const container = document.getElementById('root');
const root = container && createRoot(container);

root?.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);

// Добавляем отправку кук с каждым скриптом, добавленным SystemJS.
// Webpack вычленяет из текста обращения к SystemJS, заменяя их по собственной системе
(window as any).System.constructor.prototype.createScript = function (url: string) {
  return Object.assign(document.createElement('script'), {
    src: url,
    charset: 'utf-8',
    async: true,
  });
};

/** Переопределяем fetch. */
const { fetch: originalFetch } = window;

window.fetch = async (...params) => {
  const DISABLED_RESOURCE = ['/back/v0/auth/initialize'] as any[];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [resource, config] = params;

  const response = await originalFetch(...params);

  if (response.status === 401 && !DISABLED_RESOURCE.includes(resource)) window.location.reload();

  return response;
};

/** Инициализируем глобальный стейт `window.abit`. */
const windowAbit: WindowABIT = {
  modalCount: 0,
  addBreadcrumb: () => {},
  replaceBreadcrumb: () => {},
  deleteBreadcrumb: () => {},
};

window.abit = windowAbit;
