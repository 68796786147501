import { DialogPortal } from '@abit/ui/components/DialogPortal';
import { Background } from './styled';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import actions from 'store/actions';
import { memo } from 'react';
import { Content } from './Content';

export const SearchBar: React.FC = memo(() => {
  const dispatch = useAppDispatch();

  const visible = useAppSelector((state) => state.app.searchBar.visible);

  const toggleVisible = () => dispatch(actions.app.toggleSearchBar());

  if (!visible) return null;

  return (
    <DialogPortal>
      <Background onClick={toggleVisible} />
      <Content />
    </DialogPortal>
  );
});
