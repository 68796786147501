import { useState } from 'react';
import { Container, Input, SuperEllipseStyled } from './styled';
import { SpaceBox } from '@abit/ui/components/SpaceBox';
import { Text } from '@abit/ui/components/Text';
import { Divider } from '@abit/ui/components/Divider';

export const Content: React.FC = () => {
  const [searchText, setSearchText] = useState('');

  return (
    <Container>
      <SuperEllipseStyled p1={1} p2={35}>
        <Input
          placeholder="Search"
          autoFocus
          value={searchText}
          onChange={(e) => setSearchText(e.currentTarget.value)}
        />
        {searchText && (
          <>
            <Divider my={3} mx={6} />
            <SpaceBox px={6} pt={3} pb={6}>
              <Text variant="body2Regular">Function not available</Text>
            </SpaceBox>
          </>
        )}
      </SuperEllipseStyled>
    </Container>
  );
};
