import { ILog } from 'api/logs';
import { TableStyled } from './styled';

interface IProps {
  data: ILog[];
}

export const Table: React.FC<IProps> = ({ data }) => {
  return (
    <TableStyled>
      <thead>
        <tr>
          <th>Date</th>
          <th>Type</th>
          <th>Realm</th>
          <th>User</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {data.map(({ value, createData, updateData, type, user, realm, _id }) => (
          <tr key={_id}>
            <td color={createData !== updateData ? 'red' : undefined}>{createData}</td>
            <td>{type}</td>
            <td>{realm}</td>
            <td>{user}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </TableStyled>
  );
};
